import React, { useEffect, useState } from "react";
import "./EditJson.css";
import Select from "react-select";
import SelectDropDownIcon from "../../../assets/icons/selectDropdown.svg";
import { axiosService } from "../../../services/axios.service";
import { API_URLS } from "../../../services/constants/api_urls";
import JsonEditor from "../json-editor/JsonEditor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditJson() {
  const [jsonData, setJsonData] = useState(null);
  const [selectedParams, setSelectedParams] = useState("");
  console.log(jsonData, "jjjjjjjjjjjjjjjjjjj");
  const [fetchModules, setFetchModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [dropdownData, setDropdownData] = useState({
    fetchModules1: [],
    fetchModules2: [],
    fetchModules3: [],
    fetchModules4: [],
    fetchModules5: [],
  });
  const [selectedValues, setSelectedValues] = useState({
    categoryName: null,
    categoryName1: null,
    categoryName2: null,
    categoryName3: null,
    categoryName4: null,
    categoryName5: null,
  });

  console.log(selectedValues, "selecteddddd valueeessss");
  const customDropdownIndicator = () => (
    <img src={SelectDropDownIcon} alt="Dropdown Indicator" />
  );

  const fetchInitialDropdownData = async () => {
    try {
      const response = await axiosService.get(
        `${API_URLS.GET_USER_JSON_FETCH}`
      );

      if (response.status === 200) {
        console.log(response, "responseeeeee");

        // if (params.fileName) {
        //   setJsonData(response.data);
        //   setSelectedParams(params);
        // }
        const filteredValues = response.data.filter(
          (item) => item !== "audio" && item !== "images" && item !== "png"
        );

        console.log(filteredValues, "filteredValues");

        const values = filteredValues.map((item) => ({
          label: item.replace(/_/g, " "),
          value: item,
        }));

        setFetchModules(values);

        // setDropdownData((prevData) => ({
        //   ...prevData,
        //   [`fetchModules${
        //     Object.keys(selectedValues).filter((k) => selectedValues[k]).length
        //   }`]: values,
        // }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchInitialDropdownData();
  }, []);

  const fetchDropdownData = async () => {
    try {
      const params = {};

      Object.keys(selectedValues).forEach((key) => {
        if (selectedValues[key]) {
          if (selectedValues[key].value?.includes(".json")) {
            params.fileName = selectedValues[key].value;
          } else {
            params[key] = selectedValues[key].value;
          }
        }
      });

      const response = await axiosService.get(
        `${API_URLS.GET_USER_JSON_FETCH}`,
        {
          params,
        }
      );
      console.log(response.data, "eeeeeeeeeeeeeeeeee");
      console.log(response, "ooooooooooooooooooooooooooo");
      // setJsonData(response?.data);
      if (response.status === 200) {
        if (params.fileName) {
          setJsonData(response.data);
          setSelectedParams(params);
        }

        const values = response.data.map((item) => ({
          label: item.replace(/_/g, " "),
          value: item,
        }));

        setDropdownData((prevData) => ({
          ...prevData,
          [`fetchModules${
            Object.keys(selectedValues).filter((k) => selectedValues[k]).length
          }`]: values,
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Handle change and trigger data fetch
  const handleSelectChange = (key, selectedOption) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [key]: selectedOption,
    }));
  };

  useEffect(() => {
    if (selectedValues.categoryName) {
      fetchDropdownData();
    }
  }, [selectedValues]);

  useEffect(() => {
    setSelectedValues({
      ...selectedValues,
      categoryName1: null,
      categoryName2: null,
      categoryName3: null,
      categoryName4: null,
      categoryName5: null,
    });
    setJsonData(null);
  }, [selectedValues.categoryName]);

  useEffect(() => {
    setSelectedValues({
      ...selectedValues,
      categoryName2: null,
      categoryName3: null,
      categoryName4: null,
      categoryName5: null,
    });
    setJsonData(null);
  }, [selectedValues.categoryName1]);

  useEffect(() => {
    setSelectedValues({
      ...selectedValues,
      categoryName3: null,
      categoryName4: null,
      categoryName5: null,
    });
    setJsonData(null);
  }, [selectedValues.categoryName2]);

  useEffect(() => {
    setSelectedValues({
      ...selectedValues,
      categoryName4: null,
      categoryName5: null,
    });
    setJsonData(null);
  }, [selectedValues.categoryName3]);

  useEffect(() => {
    setSelectedValues({
      ...selectedValues,
      categoryName5: null,
    });
    setJsonData(null);
  }, [selectedValues.categoryName4]);

  return (
    <div className="flex flex-col gap-[10px] md:gap-[20px] p-[20px]">
      <div className="flex gap-[10px] md:gap-[20px] flex-wrap relative items-center">
        <Select
          options={fetchModules}
          placeholder="Select Option"
          classNamePrefix="admin_module_select"
          className="admin-select-option"
          isSearchable={false}
          components={{
            DropdownIndicator: customDropdownIndicator,
            IndicatorSeparator: null,
          }}
          value={selectedValues.categoryName}
          onChange={(option) => handleSelectChange("categoryName", option)}
        />
        {selectedValues.categoryName &&
          !selectedValues.categoryName.value?.includes(".json") && (
            <Select
              options={dropdownData.fetchModules1}
              placeholder="Select Option"
              classNamePrefix="admin_module_select"
              className="admin-select-option"
              isSearchable={false}
              components={{
                DropdownIndicator: customDropdownIndicator,
                IndicatorSeparator: null,
              }}
              value={selectedValues.categoryName1}
              onChange={(option) => handleSelectChange("categoryName1", option)}
            />
          )}
        {selectedValues.categoryName1 &&
          !selectedValues.categoryName1.value?.includes(".json") && (
            <Select
              options={dropdownData.fetchModules2}
              placeholder="Select Option"
              classNamePrefix="admin_module_select"
              className="admin-select-option"
              isSearchable={false}
              components={{
                DropdownIndicator: customDropdownIndicator,
                IndicatorSeparator: null,
              }}
              value={selectedValues.categoryName2}
              onChange={(option) => handleSelectChange("categoryName2", option)}
            />
          )}
        {selectedValues.categoryName2 &&
          !selectedValues.categoryName2.value?.includes(".json") && (
            <Select
              options={dropdownData.fetchModules3}
              placeholder="Select Option"
              classNamePrefix="admin_module_select"
              className="admin-select-option"
              isSearchable={false}
              components={{
                DropdownIndicator: customDropdownIndicator,
                IndicatorSeparator: null,
              }}
              value={selectedValues.categoryName3}
              onChange={(option) => handleSelectChange("categoryName3", option)}
            />
          )}
        {selectedValues.categoryName3 &&
          !selectedValues.categoryName3.value?.includes(".json") && (
            <Select
              options={dropdownData.fetchModules4}
              placeholder="Select Filename"
              classNamePrefix="admin_module_select"
              className="admin-select-option"
              isSearchable={false}
              components={{
                DropdownIndicator: customDropdownIndicator,
                IndicatorSeparator: null,
              }}
              value={selectedValues.categoryName4}
              onChange={(option) => handleSelectChange("categoryName4", option)}
            />
          )}
        {selectedValues.categoryName4 &&
          !selectedValues.categoryName4.value?.includes(".json") && (
            <Select
              options={dropdownData.fetchModules5}
              placeholder="Select Filename"
              classNamePrefix="admin_module_select"
              className="admin-select-option"
              isSearchable={false}
              components={{
                DropdownIndicator: customDropdownIndicator,
                IndicatorSeparator: null,
              }}
              value={selectedValues.categoryName5}
              onChange={(option) => handleSelectChange("categoryName5", option)}
            />
          )}
      </div>
      {jsonData && (
        <div className="validate-json-editor-container relative">
          <JsonEditor jsonData={jsonData} selectedParams={selectedParams} />
        </div>
      )}
    </div>
  );
}

export default EditJson;
